// Load Styles
import '../../scss/landing-vesting/main.scss';
import '../../scss/landing-vesting/fonts/GothamPro-Medium.woff';
import {initBootstrap} from "../bootstrap";

initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false
});

import Web3Component, {from} from './wallet'
import $ from "jquery";

const web3Component = new window.web3Component(window.configCrypt)

function showCancelPrivate() {
  const modal = document.querySelector('.blur-portal__private-cancel')
  modal.classList.add('active', 'animate')
}

function showSuccessBuy() {
  const modal = document.querySelector('.blur-portal__success-buy')
  modal.classList.add('active', 'animate')
}

function hideSuccessBuy() {
  const modalSuccess = document.querySelector('.blur-portal__success-buy')
  modalSuccess.classList.remove('active', 'animate')
}

function showErrorBuy() {
  const modalSuccess = document.querySelector('.blur-portal__error-buy')
  modalSuccess.classList.add('active', 'animate')
}

function hideErrorBuy() {
  const modalSuccess = document.querySelector('.blur-portal__error-buy')
  modalSuccess.classList.remove('active', 'animate')
}

function showLoader() {
  const loader = document.querySelector('.blur-portal__loader')
  loader.classList.add('active', 'animate')
}

function hideLoader() {
  const loader = document.querySelector('.blur-portal__loader')
  loader.classList.remove('active', 'animate')
}

window.modalPrivateCancel = showCancelPrivate;
window.modalSuccessBuy = showSuccessBuy;
window.modalNotStartedPrivate = showNotStartedPrivate;

// Set the date we're counting down to

const daysText = document.querySelector('#days');
const hoursText = document.querySelector('#hours');
const minutesText = document.querySelector('#minutes');

const modal = document.querySelector('.blur-portal__private-cancel')

// -----------------------------------
//              Private not started
//------------------------------------

function showNotStartedPrivate() {
  const modalNotStarted = document.querySelector('.blur-portal__private-not-started')
  modalNotStarted.classList.add('active', 'animate')
}

function hideNotStartedPrivate() {
  const modalNotStarted = document.querySelector('.blur-portal__private-not-started')
  modalNotStarted.classList.remove('active', 'animate')
}

async function checkStartPrivate() {
  const privateStart = await web3Component.getPublicVariable('privateStart')
  const privateStartFormatted = new Date(parseInt(privateStart) * 1000).getTime();
  const currentTime = Date.now();

  const buyPrivateButton = document.querySelector('#buy-btn-private');


  if (privateStartFormatted > currentTime) {
   showNotStartedPrivate()
  } else {
    buyPrivateButton.classList.remove('d-none');
  }

}
checkStartPrivate();

// -----------------------------------
//              Timer
//------------------------------------

let x = setInterval(async function () {

  let now = Date.now();

  const privateEnd = await web3Component.getPublicVariable('privateEnd');

  const endTime = new Date(parseInt(privateEnd) * 1000).getTime();

  if (endTime) {

    let distance = endTime - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));


    if (days < 10) {
      days = `0${days}`
    }

    if (hours < 10) {
      hours = `0${hours}`
    }

    if (minutes < 10) {
      minutes = `0${minutes}`
    }

    daysText.innerHTML = `${days}`;
    hoursText.innerHTML = `${hours}`;
    minutesText.innerHTML = `${minutes}`;

    if (distance < 0) {
      buyPrivateButton.classList.add('d-none');
      clearInterval(x);
      daysText.innerHTML = `00`;
      hoursText.innerHTML = `00`;
      minutesText.innerHTML = `00`;
      modal.classList.add('active', 'animate')
    }
  }
}, 1000);



// -----------------------------------
//              toast
//------------------------------------

const toastAutoShow = async (type, text) => {
  const toastHolder = document.querySelector('.no-auto-toast');
  if (toastHolder) return;
  const toast = $(".toast");
  const toastHtml = document.querySelector('.toast')
  const toastText = toastHtml.querySelector('.text')
  toastHtml.classList.add(`toast__${type}`)
  toastText.innerHTML = text;
  setTimeout(() => toast.show(), 100);
  const delayTimeout = +toast.data("delay");
  setTimeout(() => {
    toast.hide()
    toastHtml.classList.remove(`toast__${type}`);
  }, 4000);

}


// -----------------------------------
//              Web3Component
//------------------------------------

const buyPrivateButton = document.querySelector('#buy-btn-private');
const connectButton = document.querySelector('#wallet-connect');
const connectButtonText = connectButton.querySelector('#wallet-connect-text');
const connectButtonMobile = document.querySelector('#wallet-connect-mobile');
const disconnectButton = document.querySelector('#wallet-disconnect');
const disconnectButtonMobile = document.querySelector('#wallet-disconnect-mobile');
const mobileNavButton = document.querySelector('#mobile-nav');
const repeatButton = document.querySelector('#repeat-pay');
const successModalCloseButton = document.querySelector('.modal__close-success')
const errorModalCloseButton = document.querySelector('.modal__close-error')

const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

window.onload = async (event) => {

  const account = await web3Component.getAccount();

  if (account) {
    connectButtonText.innerHTML = `${account}`;
    connectButtonMobile.innerHTML = `Wallet: ${account}`;
    connectButtonText.title = `Wallet: ${account}`;
  }

  const remainingPrivateCount = await web3Component.getPublicVariable('remainingPrivateCount');

  const remainingPrivateSpan = document.querySelector('#remaining_count')
  if (remainingPrivateSpan) {
    remainingPrivateSpan.innerHTML = remainingPrivateCount;
  }


};

function disconnectWallet() {
  console.log('disconnect')
  web3Component.disconnectWallet();

  connectButtonText.innerHTML = `WalletConnect`;
  connectButtonText.title = `WalletConnect`;
  connectButtonMobile.innerHTML = 'WalletConnect';

  disconnectButton.classList.add('d-none');

  if (vw <= 992) {
    connectButton.classList.remove('d-none');
    mobileNavButton.classList.add('d-none')
  }

}

async function connectWallet() {

  try {
    await web3Component.connectWallet().then(() => {
      toastAutoShow('success', 'Кошелёк подключен')
      const account = web3Component.getAccount()

      // btn header
      connectButtonText.innerHTML = `Wallet: ${account}`;
      connectButtonText.title = `Wallet: ${account}`;

      disconnectButton.classList.remove('d-none');

      let value = '...' + account;
      do {
        value = '...' + value.substring(4);
        connectButtonMobile.innerHTML = `Wallet: <br/> ${value}`;
      } while (connectButtonMobile.scrollWidth > connectButtonMobile.offsetWidth);
      do {
        value = '...' + value.substring(4);
        connectButtonText.innerHTML = `Wallet: ${value}`;
      } while (connectButtonText.scrollWidth > connectButtonText.offsetWidth);

      if(vw <= 992) {
        disconnectButton.classList.add('d-none');
        connectButton.classList.add('d-none');

        mobileNavButton.classList.remove('d-none');
      }

      const chainId = web3Component.getProvider().chainId;
      console.log(chainId);
      if(chainId !== 56) {
        console.log('Wrong chain, please choose BSC');
      } else {
        console.log('Account', account);
      }
      web3Component.getProvider().on("chainChanged", (chainId) => {
        if(chainId !== 56) {
          console.log('Wrong chain, please choose BSC chain', account);
        } else {
          console.log('Account', account);
        }
      });
    })

  } catch (e) {
    toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
    console.error(e);
  }

}

async function buyPrivate() {
  try {
    const provider = await web3Component.getProvider();

    if (!provider || !provider.connected) {
      await connectWallet();
    }
      showLoader();

    console.log('start pay provider', provider, localStorage)

      let buy = await web3Component.buyPrivate().then((data) => {
        console.log(data);
        console.log('Транзакция прошла: https://testnet.bscscan.com/tx/'+data);
        showSuccessBuy();
      }).catch((err) => {
        console.log('Error', err);
        if(err.transactionHash) {
          console.log('Транзакция не прошла: https://testnet.bscscan.com/tx/'+err.transactionHash);
          toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
        } else if (err.message.startsWith('Error: User rejected the transaction')) {
          toastAutoShow('error', 'Операция отменена пользователем')
        } else {
          toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
        }
      }).finally(() => {
        /*disconnectWallet();*/
      });

      hideLoader()

      new Promise((resolve, reject) => {
        resolve(buy)
        setTimeout(() => {
          console.log('TimeOut')
          hideLoader()
          /*disconnectWallet();*/
          showErrorBuy()

          reject();

        }, 300000)
      });
  } catch (e) {
    /*disconnectWallet();*/
    hideLoader()
    console.error(e);
    toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
  }
}

connectButton.addEventListener('click', connectWallet);
connectButtonMobile.addEventListener('click', connectWallet);

disconnectButton.addEventListener('click', disconnectWallet);
disconnectButtonMobile.addEventListener('click', disconnectWallet);

successModalCloseButton.addEventListener('click', hideSuccessBuy);
errorModalCloseButton.addEventListener('click', hideErrorBuy);

if (buyPrivateButton) {
  buyPrivateButton.addEventListener('click', buyPrivate);
}
repeatButton.addEventListener('click', function () {
  hideErrorBuy()
  buyPrivate()
})